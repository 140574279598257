import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bounce } from "react-toastify";

import {
  FinanceDataProps,
  RMEDContentsProps,
  RMEDDictionary,
  RMEDIssueProps,
  RMEDLocationProps,
  RMEDNecessaryInfoProps,
  RMEDOpinionProps,
  RMEDPortfolioInfoProps,
  RMEDSaveProps,
  StockTransactionProps,
  TabProps,
} from "../interface/RMED.interface";
import {
  AddComma,
  getMyInfo,
  getNewDate,
  getQuarterDate,
  showToast,
  trnasfromBase64ToObject,
} from "../../../../common/commonUtil";
import { UserPreferences } from "typescript";
import {
  getFinanceDataByInfo,
  getPortfolioParticipant,
  getRMBasic,
  getRMData,
  getSBPDataByInfo,
  getStockChangeList,
  getStockTransactionDataByInfo,
  submitRiskManagement,
} from "../../../../api/repository/portfolio/PortfolioRepository";
import { SBPContentsPropsV2 } from "../../../../2.0lattice/router/reporting/reporting-detail/summary-business-plan/v2/interface/SBP.interface";
import { getAllFundAccountLight } from "../../../../api/repository/accelerator/DashboardRepository";
import { FundAccountLight, UserInfo } from "../../../../type/data";
import { StockChangeListProps } from "../../../portfolio-viewer-stock-change-history/interface/type";
import {
  StakeHolderProps,
  getShareholderList,
} from "../../../../common/StockChangeListService";

const useRiskManagementEvaluationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isViewer = useRef<boolean>(false);
  const isLoaded = useRef<boolean>(false);
  const validationCheckBox = useRef<HTMLDivElement>(null);
  const initRMED = useRef<boolean>(false);

  const fundAccount = useRef<FundAccountLight[] | undefined>(undefined);
  const necessaryInfo = useRef<RMEDNecessaryInfoProps | undefined>(undefined);
  const basicDictionary = useRef<RMEDDictionary | undefined>(undefined);
  const document = useRef<RMEDContentsProps | undefined>(undefined);
  const originDocument = useRef<RMEDContentsProps | undefined>(undefined);
  const closedCompany = useRef<boolean | undefined>(undefined);
  const portfolioInfo = useRef<RMEDPortfolioInfoProps | undefined>(undefined);
  const riskManagementId = useRef<number>(0);
  const year = useRef<number>(0);
  const quarter = useRef<number>(0);
  const mainParticipant = useRef<UserInfo | undefined>(undefined);

  const [loadCompleted, setLoadCompleted] = useState<boolean>(false);
  const [isFullData, setIsFullData] = useState<boolean>(false);
  const [tabList, updateTabList] = useState<TabProps[]>([]);
  const [selectedTabId, updateSelectedTabId] = useState<number>(1);
  const [isFolded, updateFolded] = useState<boolean>(false);
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<string>("");

  const getDocumentInfo = () => {
    const locationPath = location.pathname.split("/");
    const transformedObject = locationPath[4];
    if (locationPath.length > 5) {
      isViewer.current = locationPath[5] === "viewer";
    }
    const object = trnasfromBase64ToObject(transformedObject);
    return object;
  };

  const onClickBackBtn = () => {
    navigate(-1);
  };

  const handleResize = () => {
    if (validationCheckBox.current) {
      validationCheckBox.current.style.display = "flex";
      if (window.innerWidth > 1200 + 232 + 232 + 24) {
        const marginleft = (window.innerWidth - 1200) / 2;

        validationCheckBox.current.style.left = `${1200 + marginleft + 24}px`;
      } else if (window.innerWidth < 1200) {
        validationCheckBox.current.style.display = "none";
      } else {
        validationCheckBox.current.style.left = "";
        validationCheckBox.current.style.right = "40px";
      }
    }
  };

  const validationIssueAll = (rmedData: RMEDContentsProps) => {
    return (
      validationIssue1(rmedData) &&
      validationIssue2(rmedData) &&
      validationIssue3(rmedData) &&
      validationIssue4(rmedData)
    );
  };

  //폐업진행 유효성 검사
  const validationIssue1 = (rmedData: RMEDContentsProps) => {
    const closedIssue = rmedData.riskIssueList.find(
      (item) => item.riskIssue.id === 7
    );
    if (closedIssue === undefined) return false;
    if (closedIssue.result === undefined) return false;
    return true;
  };
  //대내적 이슈 유효성 검사
  const validationIssue2 = (rmedData: RMEDContentsProps) => {
    if (closedCompany.current !== undefined && closedCompany.current)
      return true;

    const issues = rmedData.riskIssueList.filter((item) =>
      [1, 2, 3, 6, 8, 9].includes(item.riskIssue.id)
    );

    return (
      issues.length !== 0 &&
      issues.filter((item) => {
        return item.result === undefined || item.result === null;
      }).length === 0
    );
  };
  //대외적 이슈 유효성 검사
  const validationIssue3 = (rmedData: RMEDContentsProps) => {
    if (closedCompany.current !== undefined && closedCompany.current)
      return true;
    const issues = rmedData.riskIssueList.filter((item) =>
      [4, 5].includes(item.riskIssue.id)
    );
    return (
      issues.length !== 0 &&
      issues.filter((item) => {
        return item.result === undefined || item.result === null;
      }).length === 0
    );
  };

  //담당 심사역 관리 의견 유효성 검사
  const validationIssue4 = (rmedData: RMEDContentsProps) => {
    if (closedCompany.current !== undefined && closedCompany.current)
      return true;
    return (
      rmedData.riskOpinionList.length !== 0 &&
      rmedData.riskOpinionList.filter((item) => {
        return (
          item.comment === undefined ||
          item.comment === null ||
          item.comment.length === 0 ||
          item.result === undefined ||
          item.result === null
        );
      }).length === 0
    );
  };

  const validationOpinionAll = (rmedData: RMEDContentsProps) => {
    return closedCompany.current !== undefined && closedCompany.current
      ? validationOpinion0(rmedData)
      : validationOpinion1(rmedData) && validationOpinion2(rmedData);
  };

  const validationOpinion0 = (rmedData: RMEDContentsProps) => {
    const issues = rmedData.riskIssueList.find(
      (item) => item.riskIssue.id === 7
    );
    return (
      issues !== undefined &&
      issues.comment !== undefined &&
      issues.comment !== null &&
      issues.comment.length > 0 &&
      issues.riskIssueFile !== undefined &&
      issues.riskIssueFile !== null &&
      issues.riskIssueFile.length > 0 &&
      issues.riskIssueFile[0].file !== undefined &&
      issues.riskIssueFile[0].file !== null &&
      rmedData.signatureTmp !== null &&
      rmedData.signatureTmp !== undefined
    );
  };

  const validationOpinion1 = (rmedData: RMEDContentsProps) => {
    const issues = rmedData.riskIssueList.filter(
      (item) =>
        item.result === undefined ||
        item.result === null ||
        (item.result &&
          (item.comment === undefined ||
            item.comment === null ||
            item.comment.length === 0))
    );

    return issues.length === 0;
  };

  const validationOpinion2 = (rmedData: RMEDContentsProps) => {
    return (
      rmedData.riskStatus !== null &&
      rmedData.riskStatus !== undefined &&
      rmedData.signatureTmp !== null &&
      rmedData.signatureTmp !== undefined
    );
  };

  const goToTop = () => {
    const root = window.document.querySelector("#root__component");

    if (root) {
      root.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const onTargetIntoView = (id: string) => {
    const root = window.document.querySelector("#root__component");
    const target = window.document.querySelector(`#${id}`);

    if (root && target) {
      const targetRect = target.getBoundingClientRect();
      const headerOffset = 250;
      const elementPosition = targetRect.top;
      const offsetPosition = elementPosition + root.scrollTop - headerOffset;

      root.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const checkTabValidation = (rmedData: RMEDContentsProps) => {
    const issue: TabProps = {
      id: 1,
      name: "이슈 평가",
      isComplete: validationIssueAll(rmedData),
      tabSectionList: [
        {
          index: 1,
          sectionTitle: "폐업 진행",
          isComplete: validationIssue1(rmedData),
          selectId: "issue1",
        },
        {
          index: 2,
          sectionTitle: "대내이슈",
          isComplete: validationIssue2(rmedData),
          selectId: "issue2",
        },
        {
          index: 3,
          sectionTitle: "대외이슈",
          isComplete: validationIssue3(rmedData),
          selectId: "issue3",
        },
        {
          index: 4,
          sectionTitle: "담당 심사역 관리 의견",
          isComplete: validationIssue4(rmedData),
          selectId: "issue4",
        },
      ],
    };

    const opinion: TabProps = {
      id: 2,
      name: "심사역 의견 작성",
      isComplete: validationOpinionAll(rmedData),
      tabSectionList:
        closedCompany.current !== undefined && closedCompany.current
          ? [
              {
                index: 1,
                sectionTitle: "폐업관련 담당 심사역 의견",
                isComplete: validationOpinion0(rmedData),
                selectId: "opinion1",
              },
            ]
          : [
              {
                index: 1,
                sectionTitle: "이슈 및 해결 방안",
                isComplete: validationOpinion1(rmedData),
                selectId: "opinion1",
              },
              {
                index: 2,
                sectionTitle: "종합 의견 평가",
                isComplete: validationOpinion2(rmedData),
                selectId: "opinion2",
              },
            ],
    };

    updateTabList([issue, opinion]);
  };

  const initTabList = (rmedData: RMEDContentsProps) => {
    checkTabValidation(rmedData);
  };

  const onErrorBack = (text: string) => {
    showToast({
      customElement: (
        <div
          className="heading-16-sb"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>{text}</span>
        </div>
      ),
      backgroundColor: "var(--red-500)",
      color: "var(--text-white)",
      width: 274,
      height: 48,
      milliSec: 1500,
      transition: Bounce,
      position: "bottom-center",
      toastId: new Date().toString(),
    });

    onClickBackBtn();
  };

  const setRMED = async (data?: RMEDContentsProps) => {
    if (!data || !basicDictionary.current) return;

    //data의 상태가 확정이면 백

    if (data.status === 5) {
      onErrorBack("리스트 평가가 끝난 건입니다.");
    }

    //담당자 조력자가 아니면 백
    const participantResult = await getPortfolioParticipant(
      data.portfolio.portfolioId
    );
    if (participantResult) {
      const myinfo = getMyInfo();
      mainParticipant.current = participantResult.find(
        (item) => item.screenRoleId === 1
      )?.acUser;

      setIsParticipant(
        `${myinfo.id}` === `${mainParticipant.current?.acUserId}`
      );
    } else {
      onErrorBack("잘못 된 접근 입니다.");
    }

    const newData: RMEDContentsProps = { ...data };
    year.current = newData.year;
    quarter.current = newData.quarterNum;
    portfolioInfo.current = newData.portfolio;
    riskManagementId.current = newData.id;

    if (newData.riskIssueList.length === 0) {
      const issueData = basicDictionary.current.issue.map(
        (item): RMEDIssueProps => {
          return {
            riskIssue: item,
          };
        }
      );
      newData.riskIssueList = issueData;
    }

    if (newData.riskOpinionList.length === 0) {
      const issueData = basicDictionary.current.opinion.map(
        (item): RMEDOpinionProps => {
          return {
            riskOpinion: item,
          };
        }
      );
      newData.riskOpinionList = issueData;
    }

    const curSBPData: SBPContentsPropsV2 = await getSBPDataByInfo(
      portfolioInfo.current.portfolioId,
      year.current,
      quarter.current
    );
    const prevSBPData = await getSBPDataByInfo(
      portfolioInfo.current.portfolioId,
      quarter.current === 2 ? year.current - 1 : year.current,
      quarter.current === 2 ? 4 : 2
    );

    const financeData: FinanceDataProps = await getFinanceDataByInfo(
      portfolioInfo.current.portfolioId,
      year.current,
      quarter.current === 2 ? 1 : quarter.current
    );

    const filterDate = getNewDate(
      getQuarterDate(year.current, quarter.current)
    );
    const stockTransactionData = await getStockChangeList(
      portfolioInfo.current.portfolioId
    );
    const filteredStockChangeList = stockTransactionData
      ? stockTransactionData.filter((item) => {
          const rowDate = getNewDate(item.date);
          return rowDate.getTime() <= filterDate.getTime();
        })
      : [];

    portfolioInfo.current.curSbpData = curSBPData;
    portfolioInfo.current.prevSbpData = prevSBPData;
    portfolioInfo.current.financeData = financeData;

    if (curSBPData) {
      portfolioInfo.current.maxScale =
        (curSBPData.businessPlan.estimatedCashFlow.cashOnHand || 0) +
        (curSBPData.businessPlan.estimatedCashFlow.govBalance || 0) +
        (curSBPData.businessPlan.estimatedCashFlow.expectedCashInflow || 0) +
        curSBPData.businessPlan.capitalAcquisitionPlan
          .map((item) => item.capitalAmount || 0)
          .reduce((prev, cur) => prev + cur, 0);
    }

    if (prevSBPData) {
      const scale =
        (prevSBPData.businessPlan.estimatedCashFlow.cashOnHand || 0) +
        (prevSBPData.businessPlan.estimatedCashFlow.govBalance || 0) +
        (prevSBPData.businessPlan.estimatedCashFlow.expectedCashInflow || 0) +
        prevSBPData.businessPlan.capitalAcquisitionPlan
          .map((item: any) => item.capitalAmount || 0)
          .reduce((prev: number, cur: number) => prev + cur, 0);

      if (portfolioInfo.current.maxScale) {
        portfolioInfo.current.maxScale =
          portfolioInfo.current.maxScale >= scale
            ? portfolioInfo.current.maxScale
            : scale;
      } else {
        portfolioInfo.current.maxScale = scale;
      }
    }

    closedCompany.current = newData.riskIssueList.find(
      (item) => item.riskIssue.id === 7
    )?.result;

    if (curSBPData !== undefined && stockTransactionData !== undefined) {
      setIsFullData(true);
      necessaryInfo.current = { curSBPIssue: curSBPData.reportIssue };
      const issue1 = newData.riskIssueList.find(
        (item) => item.riskIssue.id === 1
      );
      const issue3 = newData.riskIssueList.find(
        (item) => item.riskIssue.id === 3
      );
      const issue8 = newData.riskIssueList.find(
        (item) => item.riskIssue.id === 8
      );
      const issue9 = newData.riskIssueList.find(
        (item) => item.riskIssue.id === 9
      );
      if (issue1) {
        issue1.result =
          !curSBPData.businessStatus.employeeTurnover.noEventClevel &&
          curSBPData.businessStatus.employeeTurnover.cLevelChangeList !==
            undefined &&
          curSBPData.businessStatus.employeeTurnover.cLevelChangeList.length >
            0 &&
          curSBPData.businessStatus.employeeTurnover.cLevelChangeList.filter(
            (item) => item.isOnboarding === "퇴사자"
          ).length > 0;
      }
      if (issue3) {
        issue3.result =
          curSBPData.businessStatus.employeeTurnover.curEmployee !==
            undefined &&
          curSBPData.businessStatus.employeeTurnover.employeeAttrition !==
            undefined &&
          curSBPData.businessStatus.employeeTurnover.employeeAttrition >
            curSBPData.businessStatus.employeeTurnover.curEmployee / 2;
      }

      if (issue8) {
        issue8.result = financeData ? financeData.totalEquity < 0 : false;
      }

      //주식변동 내역으로 해야함
      if (issue9) {
        issue9.result = getNextInvestment(
          curSBPData,
          prevSBPData,
          filteredStockChangeList
        );
      }
    }
    document.current = structuredClone(newData);
    originDocument.current = structuredClone(newData);

    setUpdatedAt(data.updatedAt);
    initTabList(newData);
    setLoadCompleted(true);
  };

  const getNextInvestment = (
    curSbpData: any,
    prevSbpData: any,
    stockChangeList: StockChangeListProps[]
  ) => {
    if (!portfolioInfo.current) return undefined;

    const firstInvestmentDate = getNewDate(
      portfolioInfo.current.firstPaymentDate
    );

    const evaluationDate =
      quarter.current === 2
        ? getNewDate(`${year.current}-06-30`)
        : getNewDate(`${year.current}-12-31`);
    const past2Year =
      quarter.current === 2
        ? getNewDate(`${year.current - 2}-06-01`)
        : getNewDate(`${year.current - 2}-12-01`);

    const nextInvestmentFor2Year: {
      stakeholder: StakeHolderProps;
      stockNumber: number;
      investmentAmount: number;
    }[] = [];

    const validationResult = getShareholderList(stockChangeList, 0);

    stockChangeList
      .filter((item) => {
        const targetDate = getNewDate(item.date);
        return (
          targetDate.getTime() >= past2Year.getTime() &&
          targetDate.getDate() <= evaluationDate.getTime() &&
          item.stockChangeType?.id === 2
        );
      })
      .forEach((item) => {
        const target = nextInvestmentFor2Year.find(
          (stockholder) => stockholder.stakeholder.id === item.buyer?.id
        );
        if (target) {
          target.investmentAmount +=
            (item.stockNumber || 0) * (item.stockPrice || 0);
          target.stockNumber += item.stockNumber || 0;
        } else {
          nextInvestmentFor2Year.push({
            stakeholder: item.buyer!,
            stockNumber: item.stockNumber || 0,
            investmentAmount: (item.stockNumber || 0) * (item.stockPrice || 0),
          });
        }
      });

    const totalAmount = nextInvestmentFor2Year
      .map((item) => {
        return item.investmentAmount;
      })
      .reduce((p, c) => p + c, 0);

    const totalStockNumber = nextInvestmentFor2Year
      .map((item) => {
        return item.stockNumber;
      })
      .reduce((p, c) => p + c, 0);

    const totalIssuingStockNumber = validationResult.totalStockNumber;

    //당사 투자시점이 평가일 기준 2년 이내인경우 - 해당아님
    if (firstInvestmentDate.getTime() > past2Year.getDate()) {
      return false;
    }

    // 당사 투자 이후 평가일 기준으로 최근 2년간 후속투자 유치를 못하였나? - 현금흐름 이슈임
    if (nextInvestmentFor2Year.length === 0) {
      return true;
    }

    // 최근 2년간 후속투자가 있고 그 후속투자가 유의미한 후속투자 이면 - 현금 흐름 이슈 없음
    if (
      totalAmount >= 500000000 ||
      (totalAmount >= 300000000 &&
        (totalStockNumber / totalIssuingStockNumber) * 100 >= 1)
    ) {
      return false;
    }

    if (curSbpData !== undefined && prevSbpData !== undefined) {
      return (
        (prevSbpData.businessPlan.estimatedCashFlow.cashOnHand || 0) -
          (curSbpData.businessPlan.estimatedCashFlow.cashOnHand || 0) <
        0
      );
    } else {
      //보고된 요약사업계획서 2개 미만인가? 이슈있음
      return true;
    }
  };

  const init = async () => {
    if (initRMED.current) return;

    initRMED.current = true;
    isLoaded.current = false;
    const documentInfo: RMEDLocationProps = { ...getDocumentInfo() };
    if (documentInfo) {
      const dictionaryResult = await getRMBasic();
      const fundAccountResult = await getAllFundAccountLight();
      const rmedData = await getRMData(documentInfo.riskManagementId);

      basicDictionary.current = dictionaryResult;
      fundAccount.current = fundAccountResult;
      setRMED(rmedData);
    }
  };

  const areJSONsEqual = (json1: any, json2: any): boolean => {
    const stringifiedJSON1 = JSON.stringify(json1);
    const stringifiedJSON2 = JSON.stringify(json2);

    return stringifiedJSON1 === stringifiedJSON2;
  };

  const onChangeRMEDData = async (newRMEDData: RMEDContentsProps) => {
    closedCompany.current = newRMEDData.riskIssueList.find(
      (item) => item.riskIssue.id === 7
    )?.result;
    checkTabValidation(newRMEDData);
  };

  const submitRMEDData = async (isTmp: boolean) => {
    if (!document.current) {
      return;
    }
    //제출하기

    const _riskManagementId = document.current.id;

    const bodyData: RMEDSaveProps = {
      riskManagementId: _riskManagementId,
      portfolioId: document.current.portfolio.portfolioId,
      isTmp: isTmp,
      riskStatusId: closedCompany.current ? 6 : document.current.riskStatus?.id,
      signatureTmp: document.current.signatureTmp,
      riskIssueList: document.current.riskIssueList.map((item): any => {
        return {
          riskManagement: {
            id: _riskManagementId,
          },
          riskIssue: {
            id: item.riskIssue.id,
          },
          riskIssueFile: item.riskIssueFile
            ?.filter((file) => file.file !== undefined)
            ?.map((file) => {
              return { file: { id: file.file?.id } };
            }),
          result: item.result,
          comment: item.comment,
          solution: item.solution,
        };
      }),
      riskOpinionList: document.current.riskOpinionList.map((item) => {
        return {
          riskManagement: {
            id: _riskManagementId,
          },
          riskOpinion: {
            id: item.riskOpinion.id,
          },
          result: item.result,
          comment: item.comment,
        };
      }),
    };
    // 로그: BodyData 확인
    const result = await submitRiskManagement(bodyData);
    // API 결과 확인
    if (result) {
      showToast({
        customElement: (
          <div
            className="heading-16-sb"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>
              {isTmp
                ? "임시저장 되었습니다."
                : document.current.status === 4
                ? "수정후 제출 완료되었습니다."
                : "리스크 평가 제출하였습니다."}
            </span>
          </div>
        ),
        backgroundColor: "var(--green-500)",
        color: "var(--text-white)",
        width: 274,
        height: 48,
        milliSec: 1500,
        transition: Bounce,
        position: "bottom-center",
        toastId: new Date().toString(),
      });

      if (!isTmp) {
        if (document.current.status === 4) {
          navigate(-1);
        } else {
          onClickBackBtn();
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [
    isViewer,
    year,
    quarter,
    document.current,
    necessaryInfo.current,
    portfolioInfo.current,
    updatedAt,
    validationCheckBox,
    tabList,
    selectedTabId,
    isFolded,
    loadCompleted,
    isFullData,
    closedCompany.current,
    basicDictionary.current,
    isParticipant,
    mainParticipant.current,
    updateSelectedTabId,
    updateFolded,
    onChangeRMEDData,
    submitRMEDData,
    onClickBackBtn,
    onTargetIntoView,
    goToTop,
  ] as const;
};

export default useRiskManagementEvaluationDetail;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../../../assets/image/modal_close.svg";

import { DefaultButton } from "lattice_core";
import { SelectorProps, TinyUserInfo } from "../../../../../type/data";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { ChipsSelector } from "../../../../../components-2.0v/selector/ChipsSelector";
import { TextareaBox } from "../../../../../components-2.0v/input-box/TextareaBox";

interface Props {
  allUser?: TinyUserInfo[];
  defaultSubParticipant?: TinyUserInfo;
  close: () => void;
  setValue: (selectedUsers: SelectorProps, reason: string) => void;
}

export const ChangeSubParticipant: React.FC<Props> = (props) => {
  const { allUser = [], defaultSubParticipant, close, setValue } = props;

  const [allUserList, setAllUserList] = useState<SelectorProps[]>([]);
  const [changeReason, updateChangeReason] = useState<string>("");
  const [selectedUser, updateSelectedUser] = useState<
    SelectorProps | undefined
  >(undefined);

  const onClickSubmit = () => {
    if (
      !selectedUser ||
      changeReason.length === 0 ||
      selectedUser.id === defaultSubParticipant?.acUserId
    )
      return;

    const user = selectedUser;
    setValue(user, changeReason);
    close();
  };

  useEffect(() => {
    setAllUserList(
      allUser.map((item) => {
        return { id: item.acUserId, text: item.name, data: item };
      })
    );
    if (defaultSubParticipant) {
      updateSelectedUser({
        id: defaultSubParticipant.acUserId,
        text: defaultSubParticipant.name,
        data: defaultSubParticipant,
      });
    }
  }, []);

  return (
    <Container>
      <Header>
        <span className="heading-16-sb" style={{ marginTop: 10 }}>
          조력자 변경
        </span>
        <div className="close" onClick={close}>
          <Close />
        </div>
      </Header>
      <Body className="scroll__invisible">
        {/* <div className="heading-14-md" style={{ color: "var(--text-01)" }}>
          현재 조력자를 담당자로 등록하는 경우, 해당 조력자는 자동으로 조력자
          등록이 해제됩니다.
        </div> */}
        <RowTitleBox title="조력자" titleWidth={90} style={{ marginTop: 24 }}>
          <ChipsSelector
            type="single"
            width={244}
            maxHeight={200}
            searchList={allUserList}
            selectedData={selectedUser ? [selectedUser] : undefined}
            onChangeUser={(users) => {
              updateSelectedUser(users[0]);
            }}
          />
        </RowTitleBox>

        <RowTitleBox
          title="변경 사유"
          titleWidth={90}
          style={{ marginTop: 16 }}
        >
          <TextareaBox
            isShowLength
            width={292}
            maxLength={50}
            placeholder="자세하게 작성해 주세요."
            onChangeDataRealTime={(data) => updateChangeReason(data)}
          />
        </RowTitleBox>
      </Body>
      <Footer>
        <ButtonContainer>
          <DefaultButton
            className="heading-16-md"
            buttonType="text"
            sizeType="small"
            buttonColor={"#1F2022"}
            text={"취소"}
            onClick={close}
          />
          <DefaultButton
            className="heading-16-md"
            buttonType="filled"
            sizeType="small"
            buttonColor={"var(--primary-purple)"}
            hoverColor={"var(--purple-600)"}
            disabled={
              !selectedUser ||
              changeReason.length === 0 ||
              selectedUser.id === defaultSubParticipant?.acUserId
            }
            text={"확인"}
            onClick={onClickSubmit}
          />
        </ButtonContainer>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;
  min-width: 480px;
  height: 520px;
  min-height: 520px;

  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px 0 24px;

  .close {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;

    cursor: pointer;
    transition: all 0.2s ease;

    :hover {
      background-color: var(--gray-200);
    }
  }
`;

const Body = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background: white;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  padding: 28px 36px;
`;

const Footer = styled.div`
  width: 100%;
  height: 74px;
  min-height: 74px;
  max-height: 74px;
  padding: 0 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as Search } from "../../../assets/image/new_icon_search.svg";
import { ReactComponent as Lock } from "../../../assets/image/new_icon_lock.svg";
import { ReactComponent as Download } from "../../../assets/image/new_icon_download.svg";

import { ReactComponent as List } from "../../../assets/image/new_icon_list.svg";
import { ReactComponent as Close } from "../../../assets/image/new_close_button.svg";

import { GridDataProps } from "./components/grid/LatticeGrid";

import { TableVirtuoso } from "react-virtuoso";
import TableHeader from "./components/TableHeader";
import TableRow from "./components/TableRow";
import { transformObjectToBase64 } from "../../../common/commonUtil";
import { DefaultButton } from "lattice_core";
import { ReactComponent as CheckT } from "../../../assets/image/new_check_box_true.svg";

import { createReportUploadStatusList } from "../service/ReportingUploadService";
import { setReportingHistory } from "../../../common/pageHistoryUtil";
import RollingSelector from "../../../components-2.0v/rolling-selector/RollingSelector";
import { NavigationLayout } from "../../../components/navigation/NavigationLayout";
import MainTab from "../../../components-2.0v/tab/MainTab";
import DefaultSearch from "../../../components-2.0v/search/DefaultSearch";
import SubTab from "../../../components-2.0v/tab/SubTab";
import ModalLayout from "../../../components/modal/ModalLayout";
import ConfirmModal from "../../../2.0lattice/components/modal/ConfirmModal";
import useReporting from "../hook/useReporting";
import { ToggleQY } from "../../dashboard/performance-metrics/desktop/component/ToggleQY";
import { Selector } from "../../../components-2.0v/selector/Selector";
import {
  ReportingPrtfolioStatus,
  reportingPortfolioFilterList,
} from "../interface/Reporting.interface";

const DeskReporting = () => {
  const [
    isLoaded,
    myInfo,
    isQuarterOpen,
    openedQuarterPortfolioCount,
    yearSelectorList,
    paused,
    selectedYear,
    selectedQuarter,
    isDataExist,
    searchKeyword,
    selectedSortItem,
    selectedSortType,
    tempData,
    searched,
    tableRef,
    virtuoso,
    isScrolling,
    isPartiallyQuarterOpen,
    header,
    checkedData,
    subCheckedData,
    alertModalTitle,
    alertModalContents,
    alertModalPositiveTitle,
    scrollStart,
    scrollEnd,
    openQuarterModal,
    alertModal,
    showCheckedDataList,
    showDataDetail,
    dataDetail,
    closeQuarterModal,
    confirmPortfolioModal,
    statusFilter,
    updateStatusFilter,
    transfromPixel,
    statusInit,
    getReportListData,
    updateSearchKeyword,
    updateSearched,
    updateCloseQuarterModal,
    updateConfirmPortfolioModal,
    setIsScrolling,
    setScrollStart,
    setScrollEnd,
    updateCheckedData,
    updateSubCheckedData,
    getEmptyComponent,
    updateAlertModal,
    navigate,
    updateOpenQuarterModal,
    onOpenQuarter,
    onCloseQuarter,
    onConfirmPortfolio,
    updateShowCheckedDataList,
    updateShowDataDetail,
    updateDataDetail,
    checkQuarterOpen,
    onOpenSelectPortfolioQuarter,
  ] = useReporting();
  if (!isLoaded) return null;

  return (
    <Container id="root__component">
      <MainTab>
        <RollingSelector
          data={yearSelectorList}
          defaultPosition={yearSelectorList.findIndex(
            (item) => item.id === selectedYear.current
          )}
          paused={paused}
          transfromPixel={transfromPixel}
          onChangeData={(data) => {
            if (data === undefined || data.data === selectedYear.current)
              return;

            statusInit();
            selectedYear.current = data.data;
            getReportListData({
              year: data.data,
              quarterNum: selectedQuarter.current,
              search: "",
            });
          }}
        />
        {isDataExist && (
          <DefaultSearch
            value={searchKeyword}
            transfromPixel={transfromPixel}
            onChangeValue={(value) => {
              updateSearchKeyword(value);
              if (value.length === 0) {
                updateSearched(false);
                setReportingHistory({
                  search: {
                    keyword: "",
                  },
                });
                getReportListData({
                  year: selectedYear.current,
                  quarterNum: selectedQuarter.current,
                  search: value,
                  sortItem:
                    selectedSortItem.current !== -1
                      ? selectedSortItem.current
                      : undefined,
                  sortType:
                    selectedSortType.current !== -1
                      ? selectedSortType.current
                      : undefined,
                });
              }
            }}
            onPressEnter={(value) => {
              setReportingHistory({
                search: {
                  keyword: value,
                },
              });
              updateSearchKeyword(value);
              updateSearched(true);
              getReportListData({
                year: selectedYear.current,
                quarterNum: selectedQuarter.current,
                search: value,
                sortItem:
                  selectedSortItem.current !== -1
                    ? selectedSortItem.current
                    : undefined,
                sortType:
                  selectedSortType.current !== -1
                    ? selectedSortType.current
                    : undefined,
              });
            }}
          />
        )}
      </MainTab>
      <SubTab className="top__sticky">
        <QuarterContainer
          className="heading-16-md"
          style={{ fontSize: transfromPixel(16) }}
          transfromPixel={transfromPixel}
        >
          <QuarterItem
            className={`${selectedQuarter.current === 1 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              selectedQuarter.current = 1;
              statusInit();
              getReportListData({
                year: selectedYear.current,
                quarterNum: 1,
                search: "",
              });
            }}
          >
            1분기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter.current === 2 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              selectedQuarter.current = 2;
              statusInit();
              getReportListData({
                year: selectedYear.current,
                quarterNum: 2,
                search: "",
              });
            }}
          >
            2분기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter.current === 3 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              selectedQuarter.current = 3;
              statusInit();
              getReportListData({
                year: selectedYear.current,
                quarterNum: 3,
                search: "",
              });
            }}
          >
            3분기
          </QuarterItem>
          <QuarterItem
            className={`${selectedQuarter.current === 4 ? "selected" : ""}`}
            transfromPixel={transfromPixel}
            onClick={() => {
              selectedQuarter.current = 4;
              statusInit();
              getReportListData({
                year: selectedYear.current,
                quarterNum: 4,
                search: "",
              });
            }}
          >
            4분기
          </QuarterItem>
        </QuarterContainer>

        {myInfo.authorityId === 2 && isQuarterOpen && (
          <DefaultButton
            className="caption-12-md"
            buttonType="colored-outline"
            sizeType="xsmall"
            buttonColor={"#1F2022"}
            borderColor={"#D5D6D8"}
            disabled={openedQuarterPortfolioCount === 0}
            text={"분기보고 종료"}
            onClick={() => {
              updateCloseQuarterModal(true);
            }}
            transfromPixel={transfromPixel}
          />
        )}
      </SubTab>
      <ContentContainer>
        {tempData.length === 0 && (
          <ListEmptyContainer transfromPixel={transfromPixel}>
            {searchKeyword.length > 0 ? (
              <Search width={60} height={60} />
            ) : (
              <Lock width={60} height={60} />
            )}
            {getEmptyComponent()}
          </ListEmptyContainer>
        )}
        {tempData.length > 0 && (
          <ReportListContainer>
            <ReportGridTop
              className="body-14-md"
              transfromPixel={transfromPixel}
            >
              <FlexRow style={{ gap: 24 }}>
                {searched && searchKeyword.length > 0 ? (
                  <div style={{ color: "#7E8086" }}>
                    검색결과 {`${tempData.length}건`}
                  </div>
                ) : (
                  <div style={{ color: "#7E8086" }}>
                    분기보고 열린 기업{" "}
                    {`${openedQuarterPortfolioCount}/${tempData.length}`}
                  </div>
                )}
                <DownloadDocumentStatusList
                  style={{
                    paddingLeft: transfromPixel(4),
                    paddingRight: transfromPixel(4),
                    borderRadius: transfromPixel(4),
                  }}
                  onClick={() => {
                    createReportUploadStatusList(
                      `${selectedYear.current}년 ${selectedQuarter.current}분기`,
                      tempData
                        .filter((item) => {
                          if (statusFilter.id === ReportingPrtfolioStatus.ALL)
                            return true;
                          if (
                            statusFilter.id === ReportingPrtfolioStatus.MANAGE
                          )
                            return item.data?.data.phase === 5;
                          if (
                            statusFilter.id === ReportingPrtfolioStatus.DAMAGE
                          )
                            return item.data?.data.phase === 6;
                        })
                        .map((item) => item.data?.data)
                    );
                  }}
                >
                  제출현황 다운로드 <Download />
                </DownloadDocumentStatusList>
                {myInfo.authorityId === 2 && isQuarterOpen && (
                  <DefaultButton
                    className="caption-12-md"
                    buttonType="colored-outline"
                    sizeType="xsmall"
                    buttonColor={"#1F2022"}
                    borderColor={"#D5D6D8"}
                    disabled={subCheckedData.length === 0}
                    text={"일괄 확정"}
                    onClick={() => {
                      updateConfirmPortfolioModal(true);
                    }}
                    transfromPixel={transfromPixel}
                  />
                )}
              </FlexRow>

              <div>
                <Selector
                  width={80}
                  selectList={reportingPortfolioFilterList}
                  defaultData={statusFilter}
                  onChangeData={(data) => updateStatusFilter(data)}
                />
              </div>
            </ReportGridTop>
            <div
              ref={tableRef}
              style={{
                position: "relative",
                width: `100%`,
                maxWidth: `calc(100% - 102px)`,
                height: "100%",
                margin: `0 60px`,
              }}
            >
              <TableVirtuoso
                ref={virtuoso}
                className={`${isScrolling ? "on-scrollbar" : "no-scrollbar"}`}
                style={{
                  width: `100%`,
                  height: "100%",
                }}
                onScroll={(e) => {
                  setReportingHistory({
                    search: {
                      scrollTop: e.currentTarget.scrollTop,
                    },
                  });
                  setIsScrolling(true);
                  setScrollStart(e.currentTarget.scrollLeft === 0);
                  setScrollEnd(
                    e.currentTarget.scrollLeft ===
                      e.currentTarget.scrollWidth - e.currentTarget.clientWidth
                  );
                }}
                data={tempData.filter((item) => {
                  if (statusFilter.id === ReportingPrtfolioStatus.ALL)
                    return true;
                  if (statusFilter.id === ReportingPrtfolioStatus.MANAGE)
                    return item.data?.data.phase === 5;
                  if (statusFilter.id === ReportingPrtfolioStatus.DAMAGE)
                    return item.data?.data.phase === 6;
                })}
                fixedHeaderContent={() => {
                  return (
                    <TableHeader
                      headers={header}
                      transfromPixel={transfromPixel}
                      checkable={
                        myInfo.authorityId === 2 && isPartiallyQuarterOpen
                      }
                      isChecked={
                        checkedData.length ===
                        tempData.length - openedQuarterPortfolioCount
                      }
                      onCheckData={() => {
                        if (checkedData.length === tempData.length) {
                          updateCheckedData([]);
                        } else {
                          const newData: GridDataProps[] = [];
                          tempData.forEach((item) => {
                            if (
                              item.data !== undefined &&
                              !item.data.data.isOpen
                            ) {
                              newData.push(item.data);
                            }
                          });
                          updateCheckedData([...newData]);
                        }
                      }}
                      subCheckable={
                        myInfo.authorityId === 2 && !isPartiallyQuarterOpen
                      }
                      isSubChecked={subCheckedData.length === tempData.length}
                      onSubCheckData={() => {
                        if (subCheckedData.length === tempData.length) {
                          updateSubCheckedData([]);
                        } else {
                          const newData: GridDataProps[] = [];
                          tempData.forEach((item) => {
                            if (item.data !== undefined) {
                              newData.push(item.data);
                            }
                          });
                          updateSubCheckedData([...newData]);
                        }
                      }}
                    />
                  );
                }}
                itemContent={(index, data) => {
                  if (data.type === "TABLE_DATA" && data.data) {
                    return (
                      <TableRow
                        key={index}
                        headerData={header}
                        rowData={data.data}
                        transfromPixel={transfromPixel}
                        checkable={
                          myInfo.authorityId === 2 && isPartiallyQuarterOpen
                        }
                        isChecking={checkedData.length > 0}
                        isChecked={checkedData
                          .map((item) => item.identificationNumber)
                          .includes(data.data.identificationNumber)}
                        isCheckedDataNotZero={checkedData.length > 0}
                        onCheckData={(data) => {
                          const checkedIndex = checkedData.findIndex(
                            (item) =>
                              item.identificationNumber ===
                              data.identificationNumber
                          );
                          if (checkedIndex !== -1) {
                            checkedData.splice(checkedIndex, 1);
                          } else {
                            checkedData.push(data);
                          }
                          updateCheckedData([...checkedData]);
                        }}
                        subCheckable={
                          myInfo.authorityId === 2 && !isPartiallyQuarterOpen
                        }
                        isSubChecking={subCheckedData.length > 0}
                        isSubChecked={subCheckedData
                          .map((item) => item.identificationNumber)
                          .includes(data.data.identificationNumber)}
                        isSubCheckedDataNotZero={subCheckedData.length > 0}
                        onSubCheckData={(data) => {
                          const subCheckedIndex = subCheckedData.findIndex(
                            (item) =>
                              item.identificationNumber ===
                              data.identificationNumber
                          );
                          if (subCheckedIndex !== -1) {
                            subCheckedData.splice(subCheckedIndex, 1);
                          } else {
                            subCheckedData.push(data);
                          }
                          updateSubCheckedData([...subCheckedData]);
                        }}
                        onClickRow={() => {
                          if (checkedData.length > 0) return;
                          if (subCheckedData.length > 0) return;

                          const isParticipant =
                            myInfo.authorityId === 2 ||
                            data.data?.data.accessUsers.includes(myInfo.id);
                          if (
                            !myInfo.isBoardMember &&
                            myInfo.authorityId === 1 &&
                            !isParticipant
                          ) {
                            alertModalTitle.current = "접근 불가";
                            alertModalContents.current = [
                              `담당 포트폴리오가 아니면 접근할 수 없습니다.`,
                              `포트폴리오 담당 심사역에게 문의해 주세요.`,
                            ];
                            alertModalPositiveTitle.current = "확인";
                            updateAlertModal(true);
                            return;
                          }

                          const cryptoData = transformObjectToBase64({
                            id: data.data?.identificationNumber,
                            pageName: data.data?.data.portfolioName,
                            portfolioId: data.data?.identificationNumber,
                            portfolioName: data.data?.data.portfolioName,
                            year: selectedYear.current,
                            quarterNum: selectedQuarter.current,
                            search: searchKeyword,
                            sortItem:
                              selectedSortItem.current !== -1
                                ? selectedSortItem.current
                                : undefined,
                            sortType:
                              selectedSortType.current !== -1
                                ? selectedSortType.current
                                : undefined,
                            isParticipant: isParticipant,
                          });
                          const path = `/reporting/portfolioReport/${cryptoData}`;
                          navigate(path);
                        }}
                        fileDownloadCallback={() => {
                          getReportListData({
                            year: selectedYear.current,
                            quarterNum: selectedQuarter.current,
                            search: searchKeyword,
                            sortItem:
                              selectedSortItem.current !== -1
                                ? selectedSortItem.current
                                : undefined,
                            sortType:
                              selectedSortType.current !== -1
                                ? selectedSortType.current
                                : undefined,
                          });
                        }}
                        updateShowDataDetail={updateShowDataDetail}
                      />
                    );
                  }
                  return null;
                }}
              />
              {!scrollStart && <ShadowLeft />}
              {!scrollEnd && (
                <ShadowRight style={{ right: transfromPixel(18) }} />
              )}
            </div>
          </ReportListContainer>
        )}
      </ContentContainer>

      {/* 분기보고 시작하기 confirm */}
      {openQuarterModal && (
        <ModalLayout
          isOpen={openQuarterModal}
          isFullScreen
          toggle={() => {
            updateOpenQuarterModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"분기보고 시작하기"}
            contents={[
              `${selectedYear.current}년 ${selectedQuarter.current}분기를 시작할까요?`,
            ]}
            positiveTitle={"확인"}
            negativeTitle={"취소"}
            onClickNegativeBtn={() => {
              updateOpenQuarterModal(false);
            }}
            onClickPositiveBtn={() => {
              updateOpenQuarterModal(false);
              onOpenQuarter();
            }}
          />
        </ModalLayout>
      )}
      {/* 분기보고 닫기 confirm*/}
      {closeQuarterModal && (
        <ModalLayout
          isOpen={closeQuarterModal}
          isFullScreen
          toggle={() => {
            updateCloseQuarterModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"분기보고 종료하기"}
            contents={[
              `${selectedYear.current}년 ${selectedQuarter.current}분기를 종료할까요?`,
            ]}
            positiveTitle={"확인"}
            negativeTitle={"취소"}
            onClickNegativeBtn={() => {
              updateCloseQuarterModal(false);
            }}
            onClickPositiveBtn={() => {
              updateCloseQuarterModal(false);
              onCloseQuarter();
            }}
          />
        </ModalLayout>
      )}

      {/* 일괄확정 닫기 confirm*/}
      {confirmPortfolioModal && (
        <ModalLayout
          isOpen={confirmPortfolioModal}
          isFullScreen
          toggle={() => {
            updateConfirmPortfolioModal(false);
          }}
        >
          <ConfirmModal
            type="confirm"
            transfromPixel={transfromPixel}
            title={"일괄확정 하기"}
            contents={[
              `${subCheckedData.length}개 기업의 확인중 항목을 모두 확인 상태로 변경할까요?`,
            ]}
            positiveTitle={"확인"}
            negativeTitle={"취소"}
            onClickNegativeBtn={() => {
              updateConfirmPortfolioModal(false);
            }}
            onClickPositiveBtn={() => {
              updateConfirmPortfolioModal(false);
              onConfirmPortfolio();
            }}
          />
        </ModalLayout>
      )}

      {/* 분기보고 열기 alert*/}
      {alertModal && (
        <ModalLayout
          isOpen={alertModal}
          isFullScreen
          toggle={() => {
            updateAlertModal(false);
          }}
        >
          <ConfirmModal
            type="alert"
            transfromPixel={transfromPixel}
            title={alertModalTitle.current}
            contents={alertModalContents.current}
            positiveTitle={alertModalPositiveTitle.current}
            onClickPositiveBtn={() => {
              updateAlertModal(false);
              alertModalTitle.current = "";
              alertModalContents.current = [];
              alertModalPositiveTitle.current = "";
            }}
          />
        </ModalLayout>
      )}

      {showDataDetail && dataDetail && (
        <PortfolioDetailContainer
          transfromPixel={transfromPixel}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="checked__portfolio__list__header">
            <div
              className="close__btn"
              onClick={() => {
                updateShowDataDetail(0);
              }}
            >
              <Close />
            </div>
          </div>
          <div className="checked__portfolio__list">
            <div
              style={{
                padding: "20px",
                fontFamily: "Arial, sans-serif",
                lineHeight: "1.4",
                width: "100%",
              }}
            >
              {/* Startup Info */}
              <section style={{ marginBottom: "20px", width: "100%" }}>
                <h2>회사 정보</h2>
                <p>
                  <strong>회사명:</strong> {dataDetail.startupInfo.companyName}
                </p>
                <p>
                  <strong>대표자 이름:</strong> {dataDetail.startupInfo.ceoName}
                </p>
                <p>
                  <strong>대표자 연락처:</strong>{" "}
                  {dataDetail.startupInfo.ceoTel}
                </p>
                <p>
                  <strong>대표자 이메일:</strong>{" "}
                  {dataDetail.startupInfo.ceoEmail}
                </p>
                <p>
                  <strong>주소:</strong>{" "}
                  {dataDetail.startupInfo.companyAddress.fullAddr}
                </p>
                <p>
                  <strong>사업자 등록번호:</strong>{" "}
                  {dataDetail.startupInfo.businessNumber}
                </p>
                <p>
                  <strong>설립일:</strong>{" "}
                  {dataDetail.startupInfo.establishDate}
                </p>
              </section>

              {/* Participant Info */}
              <section style={{ marginBottom: "20px" }}>
                <h2>참여자 정보</h2>
                <p>
                  <strong>채널:</strong> {dataDetail.participantInfo.channel}
                </p>
                <p>
                  <strong>팀명:</strong> {dataDetail.participantInfo.teamName}
                </p>
                <p>
                  <strong>메인 참여자:</strong>{" "}
                  {dataDetail.participantInfo.mainParticipantToText}
                </p>
                <p>
                  <strong>발굴자:</strong>{" "}
                  {dataDetail.participantInfo.excavator}
                </p>
                <p>
                  <strong>발굴일:</strong>{" "}
                  {dataDetail.participantInfo.excavationDate || "정보 없음"}
                </p>
                <p>
                  <strong>팁스:</strong> {dataDetail.participantInfo.tips}
                </p>
              </section>

              {/* Business Info */}
              <section style={{ marginBottom: "20px" }}>
                <h2>사업 정보</h2>
                <p>
                  <strong>설명:</strong> {dataDetail.businessInfo.description}
                </p>
                <p>
                  <strong>산업 기술:</strong>{" "}
                  {dataDetail.businessInfo.industrialTechToText}
                </p>
                {dataDetail.businessInfo.companyImgUrl && (
                  <p>
                    <strong>회사 이미지:</strong>
                    <br />
                    <img
                      src={dataDetail.businessInfo.companyImgUrl}
                      alt="Company"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "150px",
                        marginTop: "10px",
                      }}
                    />
                  </p>
                )}
                {dataDetail.businessInfo.thumbnailImgUrl && (
                  <p>
                    <strong>썸네일 이미지:</strong>
                    <br />
                    <img
                      src={dataDetail.businessInfo.thumbnailImgUrl}
                      alt="Thumbnail"
                      style={{
                        maxWidth: "200px",
                        maxHeight: "150px",
                        marginTop: "10px",
                      }}
                    />
                  </p>
                )}
                <p>
                  <strong>회사 이메일:</strong>{" "}
                  {dataDetail.businessInfo.companyContactEmail}
                </p>
                <p>
                  <strong>회사 연락처:</strong>{" "}
                  {dataDetail.businessInfo.companyContactNumber}
                </p>
                <p>
                  <strong>문제:</strong>{" "}
                  {dataDetail.businessInfo.problem || "정보 없음"}
                </p>
                <p>
                  <strong>해결책:</strong>{" "}
                  {dataDetail.businessInfo.solution || "정보 없음"}
                </p>
              </section>
            </div>
          </div>
        </PortfolioDetailContainer>
      )}

      <CheckedPortfolioControlContainerWrapper
        className={checkedData.length > 0 ? "show" : ""}
        transfromPixel={transfromPixel}
      >
        <CheckedPortfolioControlContainer>
          <div
            className="checked__portfolio__control__btn"
            onClick={() => {
              updateShowCheckedDataList((prev) => !prev);
            }}
          >
            <List />
            {checkedData.length > 0 && showCheckedDataList && (
              <CheckedPortfolioListContainer
                transfromPixel={transfromPixel}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="checked__portfolio__list__header">
                  <div
                    className="close__btn"
                    onClick={() => {
                      updateShowCheckedDataList((prev) => !prev);
                    }}
                  >
                    <Close />
                  </div>
                </div>
                <div className="checked__portfolio__list">
                  {checkedData.map((item, index) => {
                    return (
                      <div
                        className="checked__portfolio__list__item"
                        onClick={() => {
                          const checkedIndex = checkedData.findIndex(
                            (data) =>
                              data.identificationNumber ===
                              item.identificationNumber
                          );
                          if (checkedIndex !== -1) {
                            checkedData.splice(checkedIndex, 1);
                          } else {
                            checkedData.push(item);
                          }
                          updateCheckedData([...checkedData]);
                        }}
                      >
                        <CheckT />
                        <div>{item.data.portfolioName}</div>
                      </div>
                    );
                  })}
                </div>
              </CheckedPortfolioListContainer>
            )}
          </div>
          <div
            className="heading-16-md checked__portfolio__open__btn"
            onClick={() => {
              if (checkQuarterOpen()) {
                onOpenSelectPortfolioQuarter();
              }
            }}
          >
            선택된 기업 분기보고 열기 ({checkedData.length})
          </div>
          <div
            className="checked__portfolio__control__btn"
            onClick={() => {
              updateCheckedData([]);
            }}
          >
            <Close />
          </div>
        </CheckedPortfolioControlContainer>
      </CheckedPortfolioControlContainerWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fbfcfd;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const QuarterContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuarterItem = styled.div<{ transfromPixel: (value: number) => number }>`
  width: auto;
  height: ${(props) => props.transfromPixel(40)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.transfromPixel(8)}px
    ${(props) => props.transfromPixel(13)}px;
  color: #bec0c2;
  cursor: pointer;

  &.selected {
    color: #1f2022;
    :hover {
      color: #1f2022;
      background-color: transparent;
    }
  }

  :hover {
    color: #bec0c2;
    background-color: #f3f4f4;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background: #fbfcfd;
`;

const ListEmptyContainer = styled.div<{
  transfromPixel: (value: number) => number;
}>`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ReportListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ReportGridTop = styled.div<{ transfromPixel: (value: number) => number }>`
  width: 100%;
  padding: 20px 60px 14px 60px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fbfcfd;
  user-select: none;
`;

const DownloadDocumentStatusList = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: #4584ff;
  background: transparent;
  transition: all 0.2s ease;
  :hover {
    background-color: #f3f4f4;
  }
`;

const CheckedPortfolioControlContainerWrapper = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;
    width: fit-content;
    height: ${transfromPixel(44)}px;

    transition: all 0.2s ease;
    left: 50%;
    bottom: -${transfromPixel(50)}px;
    transform: translateX(-50%);
    &.show {
      bottom: ${transfromPixel(30)}px;
    }
  `
);
const CheckedPortfolioControlContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .checked__portfolio__control__btn {
    position: relative;
    width: 56px;
    min-width: 56px;
    max-width: 56px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #d5d6d8;
    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
    border-radius: 4px;

    transition: all 0.2s ease;

    cursor: pointer;

    :hover {
      background-color: #f3f4f4;
    }
  }

  .checked__portfolio__open__btn {
    width: 254px;
    min-width: 254px;
    max-width: 254px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #173aff;
    box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
    border-radius: 4px;
    color: #ffffff;
    transition: all 0.2s ease;
    cursor: pointer;

    :hover {
      background-color: #112cbf;
    }
  }
`;

const CheckedPortfolioListContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: absolute;

    left: -${transfromPixel(163)}px;
    bottom: calc(100% + ${transfromPixel(24)}px);
    width: ${transfromPixel(382)}px;
    height: max-content;
    max-height: ${transfromPixel(520)}px;
    background-color: #ffffff;
    filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));
    border-radius: ${transfromPixel(8)}px;

    padding-bottom: ${transfromPixel(20)}px;

    ::after {
      content: "";
      position: absolute;
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 0;
      border: ${transfromPixel(14)}px solid transparent;
      border-top-color: #ffffff;
      border-bottom: 0;
      margin-left: -${transfromPixel(14)}px;
      margin-bottom: -${transfromPixel(14)}px;
    }

    .checked__portfolio__list__header {
      width: 100%;
      height: ${transfromPixel(44)}px;
      min-height: ${transfromPixel(44)}px;
      max-height: ${transfromPixel(44)}px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: ${transfromPixel(12)}px;

      .close__btn {
        width: ${transfromPixel(32)}px;
        min-width: ${transfromPixel(32)}px;
        max-width: ${transfromPixel(32)}px;
        height: ${transfromPixel(32)}px;
        min-height: ${transfromPixel(32)}px;
        max-height: ${transfromPixel(32)}px;
        transition: all 0.2s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        :hover {
          background: #f3f4f4;
          border-radius: ${transfromPixel(4)}px;
        }
      }
    }
    .checked__portfolio__list {
      width: 100%;
      height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      min-height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      max-height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 ${transfromPixel(22)}px;
      overflow: scroll;
    }

    .checked__portfolio__list__item {
      width: 100%;
      height: ${transfromPixel(50)}px;
      min-height: ${transfromPixel(50)}px;
      max-height: ${transfromPixel(50)}px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #edeeef;
      gap: ${transfromPixel(40)}px;
      padding: 0 ${transfromPixel(20)}px;

      cursor: pointer;
    }
  `
);

const ShadowLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(50, 50, 50, 0) 93%,
    rgba(50, 50, 50, 0.1) 100%
  );
  z-index: 10;
`;

const ShadowRight = styled.div`
  position: absolute;
  top: 0;

  width: 100px;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(50, 50, 50, 0) 93%,
    rgba(50, 50, 50, 0.1) 100%
  );
  z-index: 10;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const PortfolioDetailContainer = styled.div(
  ({ transfromPixel }: { transfromPixel: (value: number) => number }) => css`
    position: fixed;

    top: 50%; /* 화면 중앙으로 이동 */
    left: 50%; /* 화면 중앙으로 이동 */
    transform: translate(-50%, -50%); /* 정확히 중앙에 위치하도록 이동 */
    width: ${transfromPixel(667)}px;
    height: ${transfromPixel(550)}px;
    z-index: 10;
    background-color: #ffffff;
    filter: drop-shadow(2px 2px 10px rgba(100, 103, 109, 0.3));
    border-radius: ${transfromPixel(8)}px;

    padding-bottom: ${transfromPixel(20)}px;

    /* 말풍선 꼬리 제거 */
    ::after {
      content: none;
    }

    .checked__portfolio__list__header {
      width: 100%;
      height: ${transfromPixel(44)}px;
      min-height: ${transfromPixel(44)}px;
      max-height: ${transfromPixel(44)}px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      padding-right: ${transfromPixel(12)}px;

      .close__btn {
        width: ${transfromPixel(32)}px;
        min-width: ${transfromPixel(32)}px;
        max-width: ${transfromPixel(32)}px;
        height: ${transfromPixel(32)}px;
        min-height: ${transfromPixel(32)}px;
        max-height: ${transfromPixel(32)}px;
        transition: all 0.2s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        :hover {
          background: #f3f4f4;
          border-radius: ${transfromPixel(4)}px;
        }
      }
    }
    .checked__portfolio__list {
      width: 100%;
      height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      min-height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      max-height: calc(${transfromPixel(520)}px - ${transfromPixel(64)}px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 ${transfromPixel(22)}px;
      overflow: scroll;
    }

    .checked__portfolio__list__item {
      width: 100%;
      height: ${transfromPixel(50)}px;
      min-height: ${transfromPixel(50)}px;
      max-height: ${transfromPixel(50)}px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid #edeeef;
      gap: ${transfromPixel(40)}px;
      padding: 0 ${transfromPixel(20)}px;

      cursor: pointer;
    }
  `
);

export default DeskReporting;

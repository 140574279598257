import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  EXIT,
  FairValueAdminProps,
  INVEST,
  InvestmentHistoryAdminProps,
  MAIN_PARTICIPANT,
  NEW_STOCK,
  PHASE_CLOSURE,
  PHASE_DAMAGE,
  PHASE_EXIT,
  PHASE_MANAGE,
  PortfolioDetailAdminProps,
  SUB_PARTICIPANT,
} from "../interface/Portfolio.interface";
import { BoxComponent } from "../../../../../components-2.0v/box/BoxComponent";
import { CustomColorLabel } from "../../../../../components-2.0v/label/CustomColorLabel";
import RowTitleBox from "../../../../../components/box/RowTitleBox";
import { getNewDate } from "../../../../../common/commonUtil";
import { InvestmentInfoHeader } from "./investment-info-table/InvestmentInfoHeader";
import { InvestmentInfoRow } from "./investment-info-table/InvestmentInfoRow";
import { ParticipantHistory } from "./ParticipantHistory";
import { DefaultButton } from "lattice_core";
import { InvestExitHistoryHeader } from "./invest-exit-history-table/InvestExitHistoryHeader";
import { InvestExitHistoryRow } from "./invest-exit-history-table/InvestExitHistoryRow";
import { FairValueHistoryHeader } from "./fair-value-history-table/FairValueHistoryHeader";
import { FairValueHistoryRow } from "./fair-value-history-table/FairValueHistoryRow";

interface Props {
  portfolio: PortfolioDetailAdminProps;
  onClickInvestExitHistoryRow: (target: InvestmentHistoryAdminProps) => void;
  onClickParticipantChange: () => void;
  onClickSubParticipantChange: () => void;
  onClickSubParticipantRemove: () => void;
  onClickExcavatorChange: () => void;
  onClickPortfolioFairValueData: (target?: FairValueAdminProps) => void;
  onClickExit: () => void;
}

export const PortfolioDetail: React.FC<Props> = (props) => {
  const {
    portfolio,
    onClickInvestExitHistoryRow,
    onClickParticipantChange,
    onClickSubParticipantChange,
    onClickSubParticipantRemove,
    onClickExcavatorChange,
    onClickPortfolioFairValueData,
    onClickExit,
  } = props;

  const getInvestmentData = () => {
    const history: InvestmentHistoryAdminProps[] = [];
    portfolio.investmentAccount.forEach((item) => {
      history.push({
        id: item.investmentAccountId,
        type: INVEST,
        date: item.paymentDate,
        fundAccount: item.acFundAccount.nameKo,
        stockType: item.stockType.name,
        stock: item.stockNumber,
        stockPrice: item.issuingPrice,
        amount: item.stockNumber * item.issuingPrice,
        target:
          item.newOldStock.newOldStockId === NEW_STOCK
            ? "신주발행"
            : "구주거래",
      });
    });

    portfolio.investmentExit.forEach((item) => {
      history.push({
        id: item.id,
        type: EXIT,
        date: item.date,
        fundAccount: item.acFundAccount.nameKo,
        stockType: item.stockType.name,
        stock: item.stock,
        stockPrice: item.stockPrice,
        amount: item.stock * item.stockPrice,
        target: item.buyer ? item.buyer : item.acInvestmentOrganization.name,
      });
    });

    history.sort((a, b) => {
      const aDate = getNewDate(a.date);
      const bDate = getNewDate(b.date);

      return aDate.getTime() - bDate.getTime();
    });
    return history;
  };

  const getSubParticipant = () => {
    const subParticipant = portfolio.pfParticipant
      .filter((item) => item.screenRole.screenRoleId === SUB_PARTICIPANT)
      .map((item) => item.acUser.name);
    return subParticipant.length === 0 ? "없음" : subParticipant.join(", ");
  };

  const getLabel = () => {
    const colorStyle = {
      textColor: "",
      bgColor: "",
    };
    switch (portfolio.pfPhase.pfPhaseId) {
      case PHASE_MANAGE:
        colorStyle.bgColor = "var(--green-100)";
        colorStyle.textColor = "var(--green-700)";
        break;
      case PHASE_DAMAGE:
        colorStyle.bgColor = "var(--red-200)";
        colorStyle.textColor = "var(--red-500)";
        break;
      case PHASE_CLOSURE:
        colorStyle.bgColor = "var(--gray-300)";
        colorStyle.textColor = "var(--gray-600)";
        break;
      case PHASE_EXIT:
        colorStyle.bgColor = "var(--blue-100)";
        colorStyle.textColor = "var(--blue-500)";
        break;
      default:
        colorStyle.bgColor = "";
        colorStyle.textColor = "";
        break;
    }
    return (
      <CustomColorLabel {...colorStyle}>
        {portfolio.pfPhase.name}
      </CustomColorLabel>
    );
  };

  if (!portfolio) return null;

  return (
    <Container>
      <BoxComponent style={{ gap: 4 }}>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <PortfolioName className="heading-24-sb">
            {portfolio.companyName}
          </PortfolioName>
          {getLabel()}
        </FlexRow>
        <Description className="body-16-rg">
          {portfolio.description}
        </Description>
        <RowTitleBox title="발굴자" titleWidth={100}>
          <FlexRow
            className="body-14-sb"
            style={{
              marginTop: 6,
              width: "max-content",
              alignItems: "center",
              gap: 24,
            }}
          >
            <div>{portfolio.excavator ? portfolio.excavator.name : "없음"}</div>
            <div>
              <DefaultButton
                className="caption-10-sb"
                sizeType="xxsmall"
                buttonType="colored-outline"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-600)"
                text="발굴자 변경"
                onClick={onClickExcavatorChange}
              />
            </div>
          </FlexRow>
        </RowTitleBox>
        <RowTitleBox title="담당자" titleWidth={100}>
          <FlexRow
            className="body-14-sb"
            style={{
              marginTop: 6,
              width: "max-content",
              alignItems: "center",
              gap: 24,
            }}
          >
            <div>
              {
                portfolio.pfParticipant.find(
                  (item) => item.screenRole.screenRoleId === MAIN_PARTICIPANT
                )?.acUser.name
              }
            </div>
            <div>
              <DefaultButton
                className="caption-10-sb"
                sizeType="xxsmall"
                buttonType="colored-outline"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-600)"
                text="담당자 변경"
                onClick={onClickParticipantChange}
              />
            </div>
          </FlexRow>
        </RowTitleBox>
        <RowTitleBox title="조력자" titleWidth={100}>
          <FlexRow
            className="body-14-sb"
            style={{
              marginTop: 6,
              width: "max-content",
              alignItems: "center",
              gap: 24,
            }}
          >
            <div
              className="body-14-sb"
              style={{ marginTop: 9, width: "max-content" }}
            >
              {getSubParticipant()}
            </div>
            <div>
              <DefaultButton
                className="caption-10-sb"
                sizeType="xxsmall"
                buttonType="colored-outline"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-600)"
                text="조력자 변경"
                onClick={onClickSubParticipantChange}
              />
            </div>
            <div>
              <DefaultButton
                className="caption-10-sb"
                sizeType="xxsmall"
                buttonType="colored-outline"
                buttonColor="var(--red-500)"
                borderColor="var(--red-500)"
                text="조력자 제거"
                onClick={onClickSubParticipantRemove}
              />
            </div>
          </FlexRow>
        </RowTitleBox>
      </BoxComponent>
      <BoxComponent style={{ padding: 28, gap: 0 }}>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="heading-18-sb">사업 정보</div>
          {[PHASE_MANAGE, PHASE_DAMAGE].includes(
            portfolio.pfPhase.pfPhaseId
          ) && (
            <DefaultButton
              text="회수하기"
              className="caption-12-sb"
              buttonType="colored-outline"
              buttonColor="var(--text-01)"
              borderColor="var(--gray-600)"
              onClick={onClickExit}
            />
          )}
        </FlexRow>

        <div
          className="heading-14-md"
          style={{ marginLeft: 16, marginTop: 24 }}
        >
          투자정보
        </div>
        <TableContainer>
          <InvestmentInfoHeader />
          {portfolio.investmentInfo.map((item, index) => {
            return <InvestmentInfoRow key={index} data={item} />;
          })}
        </TableContainer>

        <div
          className="heading-14-md"
          style={{ marginLeft: 16, marginTop: 24 }}
        >
          투자 및 회수 내역
        </div>
        <TableContainer>
          <InvestExitHistoryHeader />
          {getInvestmentData().map((item, index) => {
            return (
              <InvestExitHistoryRow
                key={index}
                data={item}
                onClick={() => {
                  onClickInvestExitHistoryRow(item);
                }}
              />
            );
          })}
        </TableContainer>
      </BoxComponent>

      <BoxComponent style={{ padding: 28, gap: 0 }}>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="heading-18-sb">공정가치 평가금액 히스토리</div>
          <DefaultButton
            text="추가하기"
            className="caption-12-sb"
            buttonType="colored-outline"
            buttonColor="var(--text-01)"
            borderColor="var(--gray-600)"
            onClick={() => {
              onClickPortfolioFairValueData();
            }}
          />
        </FlexRow>

        <TableContainer>
          <FairValueHistoryHeader />
          {portfolio.pfFairValue?.map((item, index) => {
            return (
              <FairValueHistoryRow
                key={index}
                data={item}
                onClick={() => {
                  onClickPortfolioFairValueData(item);
                }}
              />
            );
          })}
        </TableContainer>
      </BoxComponent>

      <BoxComponent style={{ padding: 28 }}>
        <FlexRow
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="heading-18-sb">담당자 히스토리</div>
        </FlexRow>
        <ParticipantHistory
          participantHistory={portfolio.pfParticipantHistory}
        />
      </BoxComponent>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  min-width: 960px;

  display: flex;
  flex-direction: column;

  padding: 32px 60px;

  gap: 24px;
  overflow: scroll;
`;

const PortfolioName = styled.div`
  margin-bottom: 4px;

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 1; // 원하는 라인수
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  width: 100%;
  margin-top: 8px;
  color: var(--text-05);
`;

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  overflow: scroll;

  padding: 16px;
`;

import React from "react";
import styled from "styled-components";
import { DefaultButton } from "lattice_core";

import { ReactComponent as BackArrow } from "../../../../assets/image/icon_back_arrow.svg";

import useRiskManagementEvaluationDetail from "../hook/useRiskManagementEvaluationDetailViewer";

import { RMEDSector } from "../../evaluation-detail/desktop/components/RMEDSector";
import RowTitleBox from "../../../../components/box/RowTitleBox";
import { AutoResizableTextarea } from "../../evaluation-detail/desktop/components/AutoResizableTextarea";
import SignatureImage from "../../../task/evaluation/desktop/organism/SignatureImage";
import { CustomFileOnlyDownload } from "./components/CustomFileOnlyDownload";
import { RMEDSectorDescription } from "../../evaluation-detail/desktop/components/RMEDSectorDescription";
import { FinanceStatement } from "./components/FinanceStatement";

const DeskRiskManagementEvaluationDetailViewer = () => {
  const [
    isViewer,
    year,
    quarter,
    document,
    updatedAt,
    currentFS,
    lastYearFS,
    loadCompleted,
    closedCompany,
    closedCompanyFile,
    isParticipant,
    mainParticipant,
    stockholderListDocument,
    onClickBackBtn,
    getFileDefault,
    onClickModifyBtn,
  ] = useRiskManagementEvaluationDetail();

  return (
    <Container>
      <Header>
        <BackArrow
          className="back__btn"
          onClick={() => {
            onClickBackBtn();
          }}
        />
        <span className="heading-18-sb title">
          {document?.portfolio.companyName || ""} 리스크 평가
        </span>
      </Header>
      <Body className={isViewer ? "viewer" : ""} id="root__component">
        {document && (
          <ContentsContainer>
            <TitleContainer>
              <FlexRow
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "14px 24px",
                  borderBottom: "1px solid var(--gray-300)",
                }}
              >
                <div className="heading-24-b" style={{ color: "" }}>
                  {document.portfolio.companyName}
                </div>
                <div
                  className="heading-18-sb"
                  style={{ color: "var(--text-05)" }}
                >
                  {document.year}년{" "}
                  {document.quarterNum === 2 ? "상반기" : "온기"}
                </div>
              </FlexRow>
              <div style={{ padding: "14px 24px" }}>
                <RowTitleBox
                  title={closedCompany ? "폐업 관련 증빙자료" : "주주명부"}
                  textClassName="heading-14-md"
                  titleWidth={208}
                  style={{ width: "100%" }}
                  transfromPixel={(n) => n}
                >
                  <CustomFileOnlyDownload
                    portfolioId={document.portfolio.portfolioId}
                    year={document.year}
                    quarter={document.quarterNum}
                    defaultData={getFileDefault()}
                    placeholder={
                      closedCompany
                        ? "파일이 없습니다."
                        : "제출한 주주명부가 없습니다."
                    }
                  />
                </RowTitleBox>
              </div>
            </TitleContainer>
            {closedCompany && (
              <RMEDSector title="폐업관련 담당 심사역 의견" id="opinion1">
                <FlexColumn style={{ gap: 34 }}>
                  <FlexColumn style={{ gap: 16 }}>
                    <div className="heading-20-sb">폐업진행</div>
                    <FlexColumn style={{ gap: 8 }}>
                      <OponionWrapper>
                        <RowTitleBox
                          title="상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 7
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  </FlexColumn>
                  <FlexColumn style={{ gap: 8 }}>
                    {/* <ClosedCompanyFile
                      riskIssueFile={
                        document.riskIssueList.find(
                          (item) => item.riskIssue.id === 7
                        )?.riskIssueFile
                      }
                      onDataChange={(fileData) => {
                        const issue = document.riskIssueList.find(
                          (item) => item.riskIssue.id === 7
                        );
                        if (issue && onChangeData) {
                          issue.riskIssueFile = fileData;
                          onChangeData(data);
                        }
                      }}
                    /> */}
                  </FlexColumn>
                </FlexColumn>
              </RMEDSector>
            )}

            {!closedCompany && (currentFS || lastYearFS) && (
              <RMEDSector title="요약 재무 정보" id="opinion1">
                <FlexColumn style={{ gap: 24 }}>
                  {currentFS && (
                    <FlexColumn style={{ gap: 16 }}>
                      <RMEDSectorDescription
                        text={`${year}년 ${
                          quarter === 2 ? "1~6" : "1~12"
                        }월에 해당하는 요약 재무 정보입니다.`}
                      />
                      <FinanceStatement financeData={currentFS} />
                    </FlexColumn>
                  )}

                  {lastYearFS && (
                    <FlexColumn style={{ gap: 16 }}>
                      <RMEDSectorDescription
                        text={`${
                          year - 1
                        }년 1~12월에 해당하는 요약 재무 정보입니다.`}
                      />
                      <FinanceStatement financeData={lastYearFS} />
                    </FlexColumn>
                  )}
                </FlexColumn>
              </RMEDSector>
            )}

            {!closedCompany && (
              <RMEDSector title="이슈 및 해결 방안" id="opinion1">
                <FlexColumn style={{ gap: 34 }}>
                  {document.riskIssueList.filter(
                    (item) => item.result !== undefined && item.result
                  ).length === 0 && (
                    <div
                      className="heading-20-sb"
                      style={{
                        width: "100%",
                        height: 100,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "var(--gray-500)",
                      }}
                    >
                      해당하는 리스크 이슈가 없습니다.
                    </div>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 1
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">핵심 인력 이탈</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 1
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 1
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 2
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">경영진 이슈</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 2
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 2
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 3
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">임직원 급감</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 3
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 3
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 4
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">거시환경 영향</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 4
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 4
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 5
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">경쟁사 영향</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 5
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 5
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 6
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">Milestone 이슈</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 6
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 6
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 8
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">완전 자본잠식</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 8
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 8
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}

                  {document.riskIssueList.find(
                    (item) => item.riskIssue.id === 9
                  )?.result && (
                    <FlexColumn style={{ gap: 16 }}>
                      <div className="heading-20-sb">현금흐름 이슈</div>
                      <OponionWrapper>
                        <RowTitleBox
                          title="이슈 상세 설명"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 9
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="이슈 해결 방안"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="상세하게 작성해주세요."
                            defaultData={
                              document.riskIssueList.find(
                                (item) => item.riskIssue.id === 9
                              )?.solution
                            }
                          />
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  )}
                </FlexColumn>
              </RMEDSector>
            )}

            {!closedCompany && (
              <RMEDSector title="종합 의견 평가" id="issue4">
                <FlexColumn style={{ gap: 34 }}>
                  <FlexColumn style={{ gap: 16 }}>
                    <div className="heading-20-sb">사업적 성과 달성</div>
                    <FlexColumn style={{ gap: 8 }}>
                      <OponionWrapper>
                        <RowTitleBox
                          title="심사역 의견"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="예상 매출액과 사업 성과 달성 계획을 바탕으로 의견을 작성해 주세요."
                            defaultData={
                              document.riskOpinionList.find(
                                (item) => item.riskOpinion.id === 1
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="전망 평가"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <div className="body-14-rg" style={{ marginTop: 7 }}>
                            {document.riskOpinionList.find(
                              (item) => item.riskOpinion.id === 1
                            )?.result
                              ? "긍정"
                              : "우려"}
                          </div>
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  </FlexColumn>
                  <FlexColumn style={{ gap: 16 }}>
                    <div className="heading-20-sb">평가 가치 유지</div>
                    <FlexColumn style={{ gap: 8 }}>
                      <OponionWrapper>
                        <RowTitleBox
                          title="심사역 의견"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <AutoResizableTextarea
                            isReadOnly={true}
                            isShowWordCounter
                            placeholder="자금도달계획과 시장 환경을 바탕으로 의견을 작성해 주세요."
                            defaultData={
                              document.riskOpinionList.find(
                                (item) => item.riskOpinion.id === 2
                              )?.comment
                            }
                          />
                        </RowTitleBox>
                        <RowTitleBox
                          title="전망 평가"
                          textClassName="heading-14-md"
                          titleWidth={208}
                          style={{ width: "100%" }}
                          transfromPixel={(n) => n}
                        >
                          <div className="body-14-rg" style={{ marginTop: 7 }}>
                            {document.riskOpinionList.find(
                              (item) => item.riskOpinion.id === 2
                            )?.result
                              ? "긍정"
                              : "우려"}
                          </div>
                        </RowTitleBox>
                      </OponionWrapper>
                    </FlexColumn>
                  </FlexColumn>
                </FlexColumn>
              </RMEDSector>
            )}

            <OponionWrapper style={{ flexDirection: "row" }}>
              <div style={{ flex: 2 }}>
                {!closedCompany && (
                  <RowTitleBox
                    title="종합 의견"
                    textClassName="heading-14-md"
                    titleWidth={89}
                    transfromPixel={(n) => n}
                  >
                    <div className="body-16-rg" style={{ marginTop: 7 }}>
                      {document.riskStatus?.name}
                    </div>
                  </RowTitleBox>
                )}
              </div>
              <FlexRow style={{ flex: 1, gap: 24 }}>
                <RowTitleBox
                  title="담당 심사역"
                  textClassName="heading-14-md"
                  titleWidth={103}
                  transfromPixel={(n) => n}
                >
                  <div style={{ paddingTop: 13 }}>
                    {document?.acUser?.name || ""}
                  </div>
                </RowTitleBox>
                <RowTitleBox
                  title="서명"
                  textClassName="heading-14-md"
                  titleWidth={58}
                  transfromPixel={(n) => n}
                >
                  <div style={{ position: "relative" }}>
                    {document.signatureTmp && (
                      <Signature>
                        {document.signatureTmp.type === 0 &&
                          document.signatureTmp.url && (
                            <img
                              src={document.signatureTmp.url}
                              className="sign-img"
                            />
                          )}
                        {document.signatureTmp.type === 1 &&
                          document.signatureTmp.path && (
                            <SignatureImage
                              signatureData={document.signatureTmp.path}
                              onLoaded={(index) => {}}
                              index={1}
                            />
                          )}
                      </Signature>
                    )}
                  </div>
                </RowTitleBox>
              </FlexRow>
            </OponionWrapper>
          </ContentsContainer>
        )}
      </Body>
      {isParticipant && document?.status === 4 && (
        <Footer>
          <div className="max__width__footer">
            <FlexRow style={{ gap: 12 }}></FlexRow>
            <FlexRow style={{ gap: 12 }}>
              <DefaultButton
                className="heading-16-md"
                buttonType="colored-outline"
                sizeType="small"
                buttonColor="var(--text-01)"
                borderColor="var(--gray-400)"
                onClick={onClickModifyBtn}
                text="수정"
              />
            </FlexRow>
          </div>
        </Footer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Header = styled.div`
  width: 100%;
  height: 78px;
  min-height: 78px;
  max-height: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  gap: 16px;

  border-bottom: 1px solid var(--divider-200);

  .title {
    color: var(--text-01);
  }

  .back__btn {
    cursor: pointer;
  }
`;
const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 78px - 66px);
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background-color: var(--background-page);

  &.viewer {
    height: calc(100vh - 78px);
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  padding-top: 36px;
  padding-bottom: 60px;
  gap: 60px;
`;

const Footer = styled.div`
  width: 100%;
  height: 66px;
  min-height: 66px;
  max-height: 66px;

  border-top: 2px solid var(--divider-200);

  .max__width__footer {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--background-contents01);
  border: 1px solid var(--gray-300);
`;

const OponionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px;
  border-radius: 4px;
  background-color: var(--background-contents01);
  border: 1px solid var(--gray-300);
`;

const Signature = styled.div`
  position: absolute;
  width: 120px;
  aspect-ratio: 448/240;
  display: flex;
  top: -12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;

  .sign-img {
    width: 100%;
    height: 100%;
  }
`;

export default DeskRiskManagementEvaluationDetailViewer;
